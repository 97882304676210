import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Business = () => {
  const data = useStaticQuery(graphql`
    query BusinessImages{
      businessOne: file(relativePath: { eq: "business/business-1.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 800) {
						...GatsbyImageSharpFluid
					}
				}
      }
      
      businessTwo: file(relativePath: { eq: "business/business-2.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 800) {
						...GatsbyImageSharpFluid
					}
				}
      }
      
      businessThree: file(relativePath: { eq: "business/business-3.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 800) {
						...GatsbyImageSharpFluid
					}
				}
      }
      
      businessFour: file(relativePath: { eq: "business/business-4.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 800) {
						...GatsbyImageSharpFluid
					}
				}
      }

      businessFive: file(relativePath: { eq: "business/business-5.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 800) {
						...GatsbyImageSharpFluid
					}
				}
      }

      businessSix: file(relativePath: { eq: "business/business-6B.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 800) {
						...GatsbyImageSharpFluid
					}
				}
      }
    }
  `)

  return (
    <div id="business" className="business pt-16 pb-10 lg:pt-24">
      <div className="text-center">
        <h4 className="uppercase font-bold text-sm md:text-lg">who can use</h4>
        <h2 className="font-semibold text-3xl md:text-4xl lg:text-5xl">Business Types.</h2>
      </div>

      <div className="flex flex-wrap">
        <div className="relative w-full mx-4 mb-4 lg:mb-0 lg:mx-0 lg:w-1/2 xl:w-1/3">
          <Img fluid={data.businessOne.childImageSharp.fluid} />

          <div className="absolute z-10 bottom-0 left-0 w-full py-4 px-3 text-white bg-purple-900 bg-opacity-75">
            <div className="font-semibold text-xl mb-1">Nail salons</div>
            <div className="text-sm"></div>
          </div>
        </div>

        <div className="relative w-full mx-4 mb-4 lg:mb-0 lg:mx-0 lg:w-1/2 xl:w-1/3">
          <Img fluid={data.businessTwo.childImageSharp.fluid} />
          
          <div className="absolute z-10 bottom-0 left-0 w-full py-4 px-3 text-white bg-pink-900 bg-opacity-75">
            <div className="font-semibold text-xl mb-1">Hair and Beauty salons</div>
            <div className="text-sm"></div>
          </div>
        </div>

        <div className="relative w-full mx-4 mb-4 lg:mb-0 lg:mx-0 lg:w-1/2 xl:w-1/3">
          <Img fluid={data.businessThree.childImageSharp.fluid} />

          <div className="absolute z-10 bottom-0 left-0 w-full py-4 px-3 text-white bg-purple-900 bg-opacity-75">
            <div className="font-semibold text-xl mb-1">Spas</div>
            <div className="text-sm"></div>
          </div>
        </div>

        <div className="relative w-full mx-4 mb-4 lg:mb-0 lg:mx-0 lg:w-1/2 xl:w-1/3">
          <Img fluid={data.businessFour.childImageSharp.fluid} />

          <div className="absolute z-10 bottom-0 left-0 w-full py-4 px-3 text-white bg-pink-900 bg-opacity-75">
            <div className="font-semibold text-xl mb-1">Massage centers</div>
            <div className="text-sm">A place where a specialist person perform a hands-on technique to relax the body.</div>
          </div>
        </div>

        <div className="relative w-full mx-4 mb-4 lg:mb-0 lg:mx-0 lg:w-1/2 xl:w-1/3">
          <Img fluid={data.businessFive.childImageSharp.fluid} />

          <div className="absolute z-10 bottom-0 left-0 w-full py-4 px-3 text-white bg-purple-900 bg-opacity-75">
            <div className="font-semibold text-xl mb-1">Dental Clinics</div>
            <div className="text-sm">A place where a dentist performs dental procedures and treatments on patients.</div>
          </div>
        </div>

        <div className="relative w-full mx-4 mb-4 lg:mb-0 lg:mx-0 lg:w-1/2 xl:w-1/3">
          <Img fluid={data.businessSix.childImageSharp.fluid} />

          <div className="absolute z-10 bottom-0 left-0 w-full py-4 px-3 text-white bg-pink-900 bg-opacity-75">
            <div className="font-semibold text-xl mb-1">Health consultation</div>
            <div className="text-sm">Health specialist providing health care service.</div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default Business;