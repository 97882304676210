import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Compatibility = () => {
  const data = useStaticQuery(graphql`
    query Images{
      mockup: file(relativePath: { eq: "reservation-mockups.png" }) {
				childImageSharp {
					fluid(maxWidth: 900) {
						...GatsbyImageSharpFluid
					}
				}
      }
    }
  `)
    
  return (
    <div id="compatibility" className="compatibility overflow-hidden relative lg:pb-10">
      <div className="absolute z-10 bottom-1 left-0 w-full h-40 bg-white transform skew-y-2"></div>

      <div className="relative pt-16 z-20 lg:pt-24">
        <div className="max-w-screen-xl mx-auto px-4 xl:px-0">
          <div className="flex flex-wrap">
            <div className="order-1 flex-auto w-full lg:order-2 lg:flex-1">
              <div className="text-center text-white lg:text-left">
                <h4 className="uppercase font-bold text-sm md:text-lg">compatibility</h4>
                <h2 className="font-semibold text-3xl md:text-4xl lg:text-5xl">Ready for devices using a modern browser.</h2>
                <div className="font-normal text-base md:text-lg">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                  Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. 
                </div>
              </div>
            </div>

            <div className="order-2 flex-auto w-full lg:order-1 lg:flex-1">
              <Img fluid={data.mockup.childImageSharp.fluid} className="mt-20 transform scale-110 md:mt-32 lg:mt-40 xl:scale-125"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Compatibility;