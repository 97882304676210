import React from "react"

import Layout from "../components/layouts/layout/layout"
import SEO from "../components/seo"
import Banner from "../components/banner/banner"
import Features from "../components/features/features"
import Compatibility from "../components/compatibility/compatibilty"
import Business from "../components/business/business"
import Pricing from "../components/pricing/pricing"
import Tour from "../components/tour/tour"
import Contact from "../components/contact/contact"
import Faq from "../components/faq/faq"

const IndexPage = () => (
  <Layout>
    <SEO title="Home"/>
    <Banner />
    <Features />
    <Compatibility />
    <Business />
    <Pricing />
    <Tour />
    <Contact />
    <Faq />
  </Layout>
)

export default IndexPage
