import React from 'react'

const Faq = () => {
  function showFaq(target) {
    const item = document.querySelector(target);
    const faqs = document.querySelectorAll('.faq_item');

    faqs.forEach((elem) => {
      elem.classList.remove('active');
    });

    if (!item.classList.contains('active')) {
      item.classList.add('active');
    } else {
      item.classList.remove('active');
    }
  }

  return (
    <div id="faq" className="faq bg-blue-500 py-16 lg:py-24">
      <div className="max-w-screen-xl mx-auto px-4 xl:px-0">
        <div className="text-center text-white">
          <h4 className="uppercase font-bold text-sm md:text-lg">faq</h4>
          <h2 className="font-semibold text-3xl md:text-4xl lg:text-5xl">How can we help?</h2>
        </div>

        <div className="flex justify-center flex-wrap mt-20">
          <div className="px-4 w-full md:10/12 lg:w-8/12">
            <div className="faq_item faq_item-1">
              <div className="flex justify-between py-8">
                <div className="font-semibold text-xl">How I can register my business?</div>

                <svg onClick={e => showFaq('.faq_item-1')} className="fill-current h-8 w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                </svg>
              </div>

              <div className="pt-1 pb-6 font-light text-lg">
                You need to send us your business information at <strong>info@go3reservation.com</strong> or you can call us at <strong>+1 000-0000-0000</strong> and from there we're going in touch with you to setup your business with us.
              </div>
            </div>

            <div className="faq_item faq_item-2">
              <div className="flex justify-between py-8">
                <div className="font-semibold text-xl">How long will it take to set up my account?</div>

                <svg onClick={e => showFaq('.faq_item-2')} className="fill-current h-8 w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                </svg>
              </div>

              <div className="pt-1 pb-6 font-light text-lg">
                Just a matter of time, after we got your information and discussed the Go3Reservation to you. The account creation will take 24-48 hours to send your account.
              </div>
            </div>

            <div className="faq_item faq_item-3">
              <div className="flex justify-between py-8">
                <div className="font-semibold text-xl">Is Go3Reservation free?</div>
                <svg onClick={e => showFaq('.faq_item-3')} className="fill-current h-8 w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                </svg>
              </div>
              <div className="pt-1 pb-6 font-light text-lg">
                <strong>Yes it's absolutely free!</strong> Go3Reservation is free to use for the basic package and also we're offering more features for a reasonable price.
              </div>
            </div>

            <div className="faq_item faq_item-4">
              <div className="flex justify-between py-8">
                <div className="font-semibold text-xl">Is my business information secure?</div>

                <svg onClick={e => showFaq('.faq_item-4')} className="fill-current h-8 w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                </svg>
              </div>

              <div className="pt-1 pb-6 font-light text-lg">
                <strong>Yes it's 100% secured with us!</strong> We're not giving your information to anyone.
              </div>
            </div>

            <div className="faq_item faq_item-5 border-b">
              <div className="flex justify-between py-8">
                <div className="font-semibold text-xl">How long will it take to upgrade my account?</div>

                <svg onClick={e => showFaq('.faq_item-5')} className="fill-current h-8 w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                </svg>
              </div>

              <div className="pt-1 pb-6 font-light text-lg">
                To upgrade your account, We need 24 hours to fully upgrade your account.
              </div>
            </div>

            <div className="text-center mt-10">
              <button className="text-white font-semibold underline text-lg hover:text-purple-800">view more faqs</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faq;