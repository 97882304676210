import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Link as ScrollLink } from "react-scroll"

const Banner = () => {
  const data = useStaticQuery(graphql`
    query BannerImages{
      bannerOne: file(relativePath: { eq: "banner/banner-1.jpg" }) {
				childImageSharp {
					fluid(jpegQuality: 100, maxWidth: 2048) {
						...GatsbyImageSharpFluid
					}
				}
			}
    }
  `)

  return (
    <div className="banner">
      <div className="relative w-full max-h-screen overflow-hidden">
        <Img fluid={data.bannerOne.childImageSharp.fluid}  className="z-10"/>
        
        <div className="banner_text absolute z-20 top-0 left-0 w-full h-full flex flex-col items-center justify-center">
          <div className="banner_text-one text-center text-white md:mb-40 lg:mb-64">
            <h1 className="text-2xl font-semibold sm:text-4xl lg:text-6xl mt-20">The Best Appointment App</h1>
            <h2 className="text-lg font-light sm:text-xl lg:text-4xl">All-in-one online booking software for your business.</h2>
            <div className="mt-5 md:mt-20"></div>
            <div className="lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 inline-grid">
              <ScrollLink to="tour" smooth={true} duration={500} 
                className="bg-green-600 hover:bg-green-800 text-white text-lg font-semibold py-4 px-8 rounded-full cursor-pointer mb-2 md:mb-0 md:mr-5">VIEW A QUICK TOUR</ScrollLink>
              <ScrollLink to="contact" smooth={true} duration={500} 
                className="bg-purple-700 hover:bg-purple-900 text-white text-lg font-semibold py-4 px-8 rounded-full cursor-pointer md:ml-5">GET A FREE ACCOUNT</ScrollLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner;