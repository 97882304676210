import React from 'react';
import posterImg from "./../../images/video-placeholder.png";
import myVideo from "./../../videos/QuickTour.mp4";

const Tour = () => {
  return (
    <div id="tour" className="tour pt-16 lg:pt-20">
      <div className="text-center">
        <h4 className="uppercase font-bold text-sm md:text-lg">how it works?</h4>
        <h2 className="font-semibold text-3xl md:text-4xl lg:text-5xl">Quick video tour</h2>
        <h3 className="font-semibold text-base md:text-xl">Manage your client appointments, lower the no-show rates, easy to use and it's more than just a scheduling tool. </h3>
      </div>

      <div className="flex justify-center mt-10 relative z-20">
        <video style={{width: "60%"}} poster={posterImg} autobuffer={"true"} controls>
          <source src={myVideo} type="video/mp4" />
        </video>
      </div>
    </div>
  )
}

export default Tour;