import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Features = () => {
  const data = useStaticQuery(graphql`
    query FeatureImages{
      featureOne: file(relativePath: { eq: "features/features-onlinebooking.png" }) {
				childImageSharp {
					fluid(maxWidth: 620) {
						...GatsbyImageSharpFluid
					}
				}
      }
      
      featureTwo: file(relativePath: { eq: "features/features-walkin.png" }) {
				childImageSharp {
					fluid(maxWidth: 620) {
						...GatsbyImageSharpFluid
					}
				}
      }
      
      featureThree: file(relativePath: { eq: "features/features-plugin.png" }) {
				childImageSharp {
					fluid(maxWidth: 620) {
						...GatsbyImageSharpFluid
					}
				}
      }
      
      featureFour: file(relativePath: { eq: "features/features-customer.png" }) {
				childImageSharp {
					fluid(maxWidth: 620) {
						...GatsbyImageSharpFluid
					}
				}
      }

      featureFive: file(relativePath: { eq: "features/features-sms.png" }) {
				childImageSharp {
					fluid(maxWidth: 620) {
						...GatsbyImageSharpFluid
					}
				}
      }

      featureSix: file(relativePath: { eq: "features/features-reports.png" }) {
				childImageSharp {
					fluid(maxWidth: 620) {
						...GatsbyImageSharpFluid
					}
				}
      }

      featureSeven: file(relativePath: { eq: "features/features-card.png" }) {
				childImageSharp {
					fluid(maxWidth: 620) {
						...GatsbyImageSharpFluid
					}
				}
      }
    }
  `)

  return (
    <div id="features" className="features py-16 lg:py-24">
      <div className="max-w-screen-xl mx-auto px-4 xl:px-0">
        <div className="text-center">
          <h4 className="uppercase font-bold text-sm md:text-lg">features</h4>
          <h2 className="font-semibold text-3xl md:text-4xl lg:text-5xl">It's All About the Experience</h2>
          <h3 className="font-semibold text-base md:text-xl">All the great features you need for your business!</h3>
        </div>
        
        <div className="flex flex-wrap text-center justify-center mt-10 lg:text-left">
          <div className="flex-auto w-full max-w-lg lg:max-w-xl lg:mr-5">
            <Img fluid={data.featureOne.childImageSharp.fluid} />
          </div>

          <div className="flex-auto w-full lg:flex-1">
            <h3 className="font-semibold text-2xl mt-3 md:text-3xl lg:mt-1">Online Booking</h3>
            
            <div className="text-base mt-2 lg:text-lg">
              <ul className="list-disc ml-5 text-xl">
                <li>Accepting online booking 24/7</li>
                <li>Operating in multiple locations</li>
                <li>Reduce no shows & Double booking</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap text-center justify-center mt-10 lg:text-left">
          <div className="flex-auto w-full max-w-lg lg:max-w-xl lg:ml-5 lg:order-2">
            <Img fluid={data.featureTwo.childImageSharp.fluid} />
          </div>

          <div className="flex-auto w-full lg:flex-1 lg:order-1">
            <h3 className="font-semibold text-2xl mt-3 md:text-3xl lg:mt-1">Walk-in Waiting list</h3>
            
            <div className="text-base mt-2 lg:text-lg">
              <ul className="list-disc ml-5 text-xl">
                <li>Smarter scheduling. Giving the customers more visibility into their wait time & status.</li>
                <li>By viewing key information on waiting and scheduled customers, owner can find the best available appointment times faster and estimate wait times more accurately for walk-in customers.</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap text-center justify-center mt-10 lg:text-left">
          <div className="flex-auto w-full max-w-lg lg:max-w-xl lg:mr-5">
            <Img fluid={data.featureThree.childImageSharp.fluid} />
          </div>

          <div className="flex-auto w-full lg:flex-1">
            <h3 className="font-semibold text-2xl mt-3 md:text-3xl lg:mt-1">Website Plugin/Add-on</h3>
            
            <div className="text-base mt-2 lg:text-lg">
              <ul className="list-disc ml-5 text-xl">
                <li>Unique appointment page just for you.</li>
                <li>Modern and straight forward appointment booking.</li>
                <li>You can extend your website using this appointment plugin.</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="text-center mt-16">
          <h4 className="uppercase font-bold text-sm md:text-lg">other features</h4>
        </div>

        <div className="flex flex-wrap">
          <div className="mt-10 w-full lg:w-1/2">
            <div className="flex items-center">
              <div className="max-w-xxs flex-1">
                <Img fluid={data.featureFour.childImageSharp.fluid} />
              </div>

              <div className="flex-1 mx-2">
                <h3 className="font-semibold text-xl md:text-2xl">Customer Relationship Management</h3>
                
                <div className="text-sm mt-2 md:text-base">
                  <ul className="list-disc ml-5">
                    <li>Free customer booking website</li>
                    <li>Customer loyalty program</li>
                    <li>Build relationships with customers by keeping profiles on file.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-10 w-full lg:w-1/2">
            <div className="flex items-center">
              <div className="max-w-xxs flex-1 order-2 lg:order-1">
                <Img fluid={data.featureFive.childImageSharp.fluid} />
              </div>

              <div className="flex-1 mx-2 order-1 lg:order-2">
                <h3 className="font-semibold text-xl md:text-2xl">Notifications</h3>
                
                <div className="text-sm mt-2 md:text-base">
                  <ul className="list-disc ml-5">
                    <li>Send automatic appointment reminders to customers via SMS and/or email</li>
                    <li>Improve interactions between clients and staff</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-10 w-full lg:w-1/2">
            <div className="flex items-center">
              <div className="max-w-xxs flex-1">
                <Img fluid={data.featureSix.childImageSharp.fluid} />
              </div>

              <div className="flex-1 mx-2">
                <h3 className="font-semibold text-xl md:text-2xl">High level reports</h3>
                
                <div className="text-sm mt-2 md:text-base">
                  <ul className="list-disc ml-5">
                    <li>Manage data with easy-to-use tracking and reporting tools</li>
                    <li>Including insights booking report and sales reports</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-10 w-full lg:w-1/2">
            <div className="flex items-center">
              <div className="max-w-xxs flex-1 order-2 lg:order-1">
                <Img fluid={data.featureSeven.childImageSharp.fluid} />
              </div>

              <div className="flex-1 mx-2 order-1 lg:order-2">
                <h3 className="font-semibold text-xl md:text-2xl">Payments</h3>
                
                <div className="text-sm mt-2 md:text-base">
                  <ul className="list-disc ml-5">
                    <li>Managing your daily sales and deposits to encompass cash, card and advance payments</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center mt-16">
          <button className="bg-transparent hover:bg-purple-800 text-purple-800 font-semibold text-xl 
            hover:text-white py-4 px-8 border border-purple-800 hover:border-transparent rounded-full">
            view all features
          </button>
        </div>
      </div>
    </div>
  )
}

export default Features;