import React from 'react'
import {Link as ScrollLink} from 'react-scroll'

const Pricing = () => {
  return (
    <div id="pricing" className="pricing overflow-hidden relative lg:pb-10">
      <div className="absolute z-10 bottom-1 left-0 w-full h-40 bg-white transform -skew-y-2"></div>

      <div className="relative pt-16 z-20 lg:pt-24">
        <div className="max-w-screen-xl mx-auto px-4 xl:px-0">
          <div className="text-center text-white">
            <h4 className="uppercase font-bold text-sm md:text-lg">our pricing plans</h4>
            <h2 className="font-semibold text-3xl md:text-4xl lg:text-5xl">Everything’s Included</h2>
            <h3 className="font-semibold text-base md:text-xl">Find the plan that’s perfect for your business. </h3>
          </div>

          <div className="flex justify-center items-center mt-10 md:mt-20">
            <div className="text-white p-8 rounded-md bg-gradient-to-r from-pink-800 via-pink-700 to-purple-900" style={{ width: '300px' }}>
              <h4 className="font-semibold text-2xl mb-2">Limited Offer</h4>

              <h2 className="font-semibold text-5xl">Free</h2>

              <div className="border-t-2 pt-5 mb-16"> 
                <h5 className="text-lg">Free Setup</h5>
                <h5 className="text-lg">Full access</h5>
                <h5 className="text-lg">24/7 Customer Support</h5>
              </div>

              <div className="mb-3 text-xl italic mb-5">*Limited time offer</div>
              <ScrollLink to="contact" smooth={true} duration={500} 
							className="bg-purple-700 hover:bg-purple-900 text-white text-lg font-semibold py-4 px-8 rounded-full cursor-pointer">Avail Now</ScrollLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default Pricing;