import React, { Component } from 'react';
import axios from 'axios';
import swal from 'sweetalert2';


class Contact extends Component {

  handleSubmitForm = e => {
    e.preventDefault();
    let name = document.querySelector('#name');
    let mobile = document.querySelector('#mobile');
    let email = document.querySelector('#email');
    let business = document.querySelector('#business');

    if (name.value === "") {
      swal.fire('Name is required', '', 'warning');
      return false;
    }

    if (mobile.value === "") {
      swal.fire('Mobile is required', '', 'warning');
      mobile.focus();
      return false;
    }

    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(email.value)) {
      swal.fire('Invalid email', '', 'warning');
      email.focus();
      return false;
    }

    axios.post('https://go3reservation.com/core/sendemail.php', {
      name: name.value,
      email: email.value,
      mobile: mobile.value,
      business: business.value
    }).then((response) => {
      swal.fire('Our sales agent wil contact you as soon as posible. Thank you for sending your information with us.', '','success')
      name.value = '';
      mobile.value = '';
      email.value = '';
      business.value = '';
    }).catch((error) => {
      swal.fire('Error: Please try again later.', '', 'error')
    })
  }

  render() {
    return (
      <div id="contact" className="contact relative -mt-24 pb-16 pt-24 lg:pt-40 lg:pb-24">
        <div className="relative text-center text-white">
          <h4 className="uppercase font-bold text-sm md:text-lg">contact us</h4>
          <h2 className="font-semibold text-3xl md:text-4xl lg:text-5xl">Send us your information</h2>
          <h3 className="font-semibold text-base md:text-xl">Our sales agent wil contact you as soon as posible.</h3>
        </div>
        <div className="relative max-w-screen-xl mt-10 mx-auto px-4 xl:px-0">
          <form>
            <div className="flex flex-wrap">
              <div className="px-2 w-full md:w-1/2">
                <input className="appearance-none block w-full bg-transparent text-white text-lg font-semibold border-2 border-white rounded-full 
                py-4 px-8 mb-3 leading-tight placeholder-white focus:outline-none focus:bg-white focus:text-gray-800 " id="name" name="name"
                  type="text" placeholder="enter your name" />
              </div>

              <div className="px-2 w-full md:w-1/2">
                <input className="appearance-none block w-full bg-transparent text-white text-lg font-semibold border-2 border-white rounded-full 
                py-4 px-8 mb-3 leading-tight placeholder-white focus:outline-none focus:bg-white focus:text-gray-800 " id="mobile" name="phone"
                  type="text" placeholder="enter your mobile number" />
              </div>

              <div className="px-2 w-full md:w-1/2">
                <input className="appearance-none block w-full bg-transparent text-white text-lg font-semibold border-2 border-white rounded-full 
                py-4 px-8 mb-3 leading-tight placeholder-white focus:outline-none focus:bg-white focus:text-gray-800 " id="email" name="email"
                  type="text" placeholder="enter your email" />
              </div>

              <div className="px-2 w-full md:w-1/2">
                <input className="appearance-none block w-full bg-transparent text-white text-lg font-semibold border-2 border-white rounded-full 
                py-4 px-8 mb-3 leading-tight placeholder-white focus:outline-none focus:bg-white focus:text-gray-800 " id="business" name="business"
                  type="text" placeholder="enter your business name" />
              </div>

              <div className="relative px-2 w-full md:w-1/2 hidden">
                <select className="block appearance-none w-full bg-transparent border-2 border-white text-white text-lg font-semibold py-4 px-8 pr-16 rounded-full
                leading-tight placeholder-white focus:outline-none focus:bg-white focus:text-gray-800" id="plan" name="plan" placeholder="select your desired plan"
                  defaultValue="">
                  <option value="" disabled>select your desired plan</option>
                  <option value="Free">Free</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-6 text-white -mt-2">
                  <svg className="fill-current h-8 w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="text-center mt-5">
              <button className="bg-purple-600 hover:bg-purple-900 text-white text-lg font-semibold py-4 px-8 rounded-full" onClick={e => this.handleSubmitForm(e)}>
                Submit
            </button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default Contact;